
* {
  margin:0;
  padding:0; 
  font-weight:normal;
  font-family:'Whitney Light Regular';
  color:#660625;
  letter-spacing: 0.5px; 
}
html, body {    
  overflow-x: hidden;
}
a {
  color: white!important;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: white!important;
  text-decoration: none;
  color:white
}
.left {
  float:left
}
.backgroundToggle {
  position: absolute;
  height: 44px;
  width: 100px;
  margin-top: -10px;
  margin-left: -10px;
  cursor: pointer;
}
textarea,
textarea::-webkit-input-placeholder,
input,
option,
input::-webkit-input-placeholder {
    font-size: 0.8em
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cacaca!important;
  opacity: 0.7; /* Firefox */
  font-style: italic;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cacaca!important;
  font-style: italic;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #cacaca!important;
  font-style: italic;
}
#spinner {
  margin-top:250px;
  margin-bottom:1500px
}
.react-datepicker__navigation {
  top:9px!important
}
.form-number {
  width:100%;
  max-width:400px;
  margin:0 auto;
  margin-top:15px;
  margin-bottom:15px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] { -moz-appearance:textfield; }
select {    
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 51%;
}
.form-control {
  border-radius:10px
}
.form-check-input[type=checkbox] {
  border-radius: 0.45em!important
}
.form-check-input {
  border:1px solid rgb(136 136 136 / 100%)!important
}
.btn {
  font-weight: 600!important;
  letter-spacing: 1px!important
}
.bottom-call-popup { 
  border: 1px solid rgb(232, 232, 232);
  background: rgb(250,100,19);
  background: linear-gradient(234deg, rgba(250,100,19,1) 0%, rgba(224,121,102,1) 72%, rgba(223,99,128,1) 87%, rgba(226,30,205,1) 100%);
  border-radius:45px;
  margin-top:20px; 
  padding:25px;  
  padding-top:12px;
  padding-bottom:14px;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: rgb(250,100,19)!important;
  border-color: rgb(250,100,19)!important;
}
.center {
    text-align:center
}
.nav-link {
  cursor:pointer
}
.modal-content { 
  border-radius: 20px!important
}
.sub-title-modal { 
  margin-bottom:30px;
  font-size:1.4em;
  font-weight:500;
  text-align:center;
  color:#660625!important
}
.modal-body { 
  padding: 60px!important
}
.title-modal {
  font-size:2.8em;
  margin-top:0;
  margin-bottom:30px;
  text-align:center;
  color:#660625!important
}
.title-modal-green {
  font-size:2.8em;
  margin-top: -25px; 
  margin-bottom: 50px;
  text-align:center;
  color:#476647!important
}
.react-datepicker__year .react-datepicker__year-text { 
  width: 47%!important
}
.menu .container {
  max-width:100%;
  padding-left:30px;
  padding-right:30px;
  position:relative;
  z-index:100
}
.show {
  display:block
}
.hide {
  display:none
}
input[type=checkbox], input[type=radio] { 
  height: 25px;
  width: 25px;
  margin-left: -2.25rem;
}
.modal-open .container-fluid,
.modal-open .container {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}
.modal-backdrop {
  background-color:#7d7d7d!important
}
.menu {
  width:100%;
  height:60px; 
  text-align:center;
} 
.navbar-brand {
  font-size:2.2em;
  font-weight:700
}   
.close-modal { 
  border-bottom:0!important
} 
.close-modal svg {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 10px;
  width: 40px!important;
  height: 40px;
  border-radius: 50%; 
  background-color: #e4e4e4;
  cursor:pointer
}  
.close-modal svg path{
  color: #660625!important
}
.hide-visibility {
  visibility:hidden
}
.show-visibility {
  visibility:show
}
.w100 {
  width:100%
}
.input-group-text {    
  padding: 8px;
  padding-top: 4px;
}
.rounded {
  border:1px solid rgb(232, 232, 232);
  padding:10px 25px 10px 25px!important;
  border-radius:45px!important;
}
.margin-left-l {
  margin-left:15px
}
.white {
  color:white!important
}
.font-l {
  font-size:1.2em
}
textarea {
  height:auto!important;
  resize: vertical!important
}
.footer {
  margin-top:80px;
  margin-bottom:150px;
  padding-bottom:35px;
  position:relative;
  z-index:100
}
.footer .container {
  max-width:75%
}
.copyright {
  font-size:0.6em;
  color:white!important;
  opacity:0.7
}
.form-control { 
  height: auto!important;
  padding: 10px 13px 10px 13px!important;
  font-size: 1.2em!important;
}
#letter-1 {
  color:#fb1568
}
#letter-2 {
  color:#fb1568
}
#letter-3 {
  color:#fb1568
}
#letter-4 {
  color:#fb1c63
}
#letter-5 {
  color:#fc3056
}
#letter-6 {
  color:#fc3c4f
}
#letter-7 {
  color:#fd543f
}
#letter-8 {
  color:#fd6335
}
#letter-9 {
  color:#fe752a
}


@media (max-width: 770px) {
  #container-all {
    display:none!important
  }
  #recommender-medical .contenido {
    padding-top: 30px!important;
  } 
  #contratation .contenido {
      padding-top: 30px!important;
  }
  #contratation .icon-recommender {
    margin-top:0!important;
  }
}